import styled, { keyframes } from "styled-components";
import { Theme } from "../../../store/theme/theme";
import { motion } from "framer-motion";
export const svgAdditionalStyle = {
  width: "35px",
  margin: "10px",
};
export const rotate = keyframes`
  from {
    transform: rotate(0deg) scale(1);
    color:${Theme.light.thirdColor.dark};
  }

  to {
    transform: rotate(360deg) scale(2.5);
    color:blue;
  }
`;
export const scale = keyframes`
  from {
    transform: scale(1);
    color:${Theme.light.thirdColor.dark};
  }

  to {
    transform: scale(1.5);
    color:blue;
  }
`;
export const singleTargetVariants = {
  hidden: () => ({
    opacity: 0,
    scale: 0.2,
    originX: "center",
    originY: "center",
  }),
  visible: () => ({
    opacity: 1,
    scale: [1, 1.2, 1],
    originX: "center",
    originY: "center",
    transition: {
      duration: 0.3,
      delay: 0.1,
    },
  }),
  exit: {
    opacity: 0,
    scale: 0.2,
  },
};
export const StyledForProductsPage = styled.div.attrs((props) => ({
  style: {
    top: `${props.top}%`,
    left: `${props.left}%`,
  },
}))`
  position: absolute;
  width: 40px;
  height: 40px;
  color: ${Theme.light.thirdColor.dark};
  transform-origin: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s color;
  &:hover {
    color: ${Theme.light.fourthColor.red};
  }
`;
export const AnimationForProductsPage = styled.div`
  width: 50%;
  height: 50%;
  animation: ${scale} 1s linear infinite alternate;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledForTarget = styled.div.attrs((props) => ({
  style: {
    top: `${props.top}%`,
    left: `${props.left}%`,
  },
}))`
  position: absolute;
  width: 40px;
  height: 40px;
  color: ${Theme.light.thirdColor.dark};
  transform-origin: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s color;
  &:hover {
    color: ${Theme.light.fourthColor.red};
  }
`;
export const AnimationForTarget = styled.div`
  width: 50%;
  height: 50%;
  animation: ${rotate} 2s linear infinite alternate;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TargetOptions = styled(motion.div).attrs((props) => ({
  style: {
    background: `${
      props.isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white
    }`,
    top: `${props.top > 80 ? -props.top * 3 : 25}%`,
    left: `${props.left > 60 ? -props.left * 4 : 25}%`,
  },
}))`
  position: absolute;
  min-width: 130px;
  color: ${Theme.light.primaryColor.white};
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 15px;
  @media${Theme.device.tablet} {
    padding: 0;
  }
  // &:hover{
  //   color:${Theme.light.secondaryColor.green};
  // }
`;
export const AddToShopingListOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  transition: 0.3s color;
  & > a {
    color: ${Theme.light.primaryColor.white};
    text-decoration: none;
    transition: 0.3s color;
  }
  &:hover,
  & > a:hover {
    color: ${Theme.light.secondaryColor.green};
  }
  @media${Theme.device.tablet} {
    font-size: 12px;
  }
`;
export const HrefShopingListOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  transition: 0.3s color;
  & > a {
    color: ${Theme.light.primaryColor.white};
    text-decoration: none;
    transition: 0.3s color;
  }
  &:hover,
  & > a:hover {
    color: ${Theme.light.secondaryColor.green};
  }
  @media${Theme.device.tablet} {
    font-size: 12px;
  }
`;
