import { Theme } from "../../../../store/theme/theme";
export const resizeScreen = (
  btnVisible,
  setBtnVisible,
  percent,
  width,
  maxWidth
) => {
  const getNumberVisibleSLides = (percent, width, innerWidth, maxWidth) => {
    if (innerWidth > maxWidth) {
      return (maxWidth * percent) / width;
    }
    return (innerWidth * percent) / width;
  };
  window.addEventListener("resize", () => {
    const docWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    const nrSlides = getNumberVisibleSLides(percent, width, docWidth, maxWidth);
    if (window.innerWidth > Theme.breakPoint.desktop) {
      setBtnVisible({ visible: true, slidesNr: nrSlides });
    } else if (window.innerWidth > Theme.breakPoint.tablet) {
      setBtnVisible({ visible: false, slidesNr: nrSlides });
    } else if (window.innerWidth > Theme.breakPoint.mobileL) {
      setBtnVisible({ visible: false, slidesNr: nrSlides });
    } else if (window.innerWidth > Theme.breakPoint.mobileM) {
      setBtnVisible({ visible: false, slidesNr: nrSlides });
    } else {
      setBtnVisible({ visible: false, slidesNr: nrSlides });
    }
  });
};
export const onLoadScreen = (
  btnVisible,
  setBtnVisible,
  percent,
  width,
  maxWidth
) => {
  const getNumberVisibleSLides = (percent, width, innerWidth, maxWidth) => {
    if (innerWidth > maxWidth) {
      return (maxWidth * percent) / width;
    }
    return (innerWidth * percent) / width;
  };

  const docWidth =
    document.documentElement.clientWidth || document.body.clientWidth;
  const nrSlides = getNumberVisibleSLides(percent, width, docWidth, maxWidth);
  if (window.innerWidth > Theme.breakPoint.desktop) {
    setBtnVisible({ visible: true, slidesNr: nrSlides });
  } else if (window.innerWidth > Theme.breakPoint.tablet) {
    setBtnVisible({ visible: false, slidesNr: nrSlides });
  } else if (window.innerWidth > Theme.breakPoint.mobileL) {
    setBtnVisible({ visible: false, slidesNr: nrSlides });
  } else if (window.innerWidth > Theme.breakPoint.mobileM) {
    setBtnVisible({ visible: false, slidesNr: nrSlides });
  } else {
    setBtnVisible({ visible: false, slidesNr: nrSlides });
  }
};
