import { Theme } from "../../store/theme/theme";
export const sliderStyle = {
  // height: '90vh',
  width: "100%",
  textAlign: "center",
  color: `${Theme.light.primaryColor.white}`,
};
export const slideStyle = {
  height: "100%",
  // width: '100%',
  // margin: "10px 70px",
  // border: "1px solid white",
  // borderRadius: "5px",
  // overflow: "hidden",
  // cursor: "pointer",
  // transition: ".3s",
  position: "relative",
};
export const buttonBackStyle = {
  position: "absolute",
  top: "50%",
  left: "0",
  transform: "translate(0,-50%)",
  width: "30px",
  height: "50px",
  backgroundColor: "transparent",
  border: "none",
  // color: `${Theme.light.primaryColor.white}`,
};
export const buttonNextStyle = {
  position: "absolute",
  top: "50%",
  right: "0",
  transform: "translate(0,-50%)",
  width: "30px",
  height: "50px",
  backgroundColor: "transparent",
  border: "none",
  // color: `${Theme.light.primaryColor.white}`,
};
export const btnStyle = {
  width: "25px",
};
export const imgSuspenseStyle = {
  width: "55%",
  color: `${Theme.dark.fiveColor.white}`,
};
