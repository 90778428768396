import {
  TRIGER_VISIBLE_LIST,
  PUSH_ITEM_TO_LIST,
  PUSH_USER_ITEM_TO_LIST,
  DELETE_ITEM_FROM_LIST,
  DELETE_USER_ITEM_TO_LIST,
  UPDATE_ITEM_ON_LIST,
  UPDATE_USER_ITEM_TO_LIST,
  USER_LOGGED,
  USER_LOGOUT,
  CLEAR_ALL_SHOPING_LIST,
  SET_ERR,
  USER_RODO_ACCEPT,
  SEND_TOKEN_TO_USER,
  USER_CAN_CROP_FROM_NEWSPAPER,
  USER_CAN_NOT_CROP_FROM_NEWSPAPER,
  ClOSE_VISIBLE_LIST,
  USER_ACCEPT_SHOPINGLIST_FUTURE,
  USER_ACCEPT_METRICS_FUTURE,
  UPDATE_ITEMS_FROM_LOCALSTORAGE,
  UPDATE_USER_ITEMS_FROM_LOCALSTORAGE,
} from "../types/type";

export const trigerVisibleList = (item) => ({
  type: TRIGER_VISIBLE_LIST,
  item,
});

export const pushItemToList = (item) => ({
  type: PUSH_ITEM_TO_LIST,
  item,
});

export const updateItemOnList = (item) => ({
  type: UPDATE_ITEM_ON_LIST,
  item,
});

export const deleteItemFromList = (item) => ({
  type: DELETE_ITEM_FROM_LIST,
  item,
});
export const pushUserItemToList = (item) => ({
  type: PUSH_USER_ITEM_TO_LIST,
  item,
});

export const updateUserItemOnList = (item) => ({
  type: UPDATE_USER_ITEM_TO_LIST,
  item,
});

export const deleteUserItemFromList = (item) => ({
  type: DELETE_USER_ITEM_TO_LIST,
  item,
});
export const usserLogged = (item) => ({
  type: USER_LOGGED,
  item,
});
export const usserLoggout = (item) => ({
  type: USER_LOGOUT,
  item,
});
export const clearAllShopingList = (item) => ({
  type: CLEAR_ALL_SHOPING_LIST,
  item,
});
export const setErr = (item) => ({
  type: SET_ERR,
  item,
});
export const userAccept = (item) => ({
  type: USER_RODO_ACCEPT,
  item,
});
export const sendToken = (item) => ({
  type: SEND_TOKEN_TO_USER,
  item,
});
export const userCanCrop = (item) => ({
  type: USER_CAN_CROP_FROM_NEWSPAPER,
  item,
});
export const userCanNotCrop = (item) => ({
  type: USER_CAN_NOT_CROP_FROM_NEWSPAPER,
  item,
});
export const closeVisibleList = (item) => ({
  type: ClOSE_VISIBLE_LIST,
  item,
});
export const userAcceptShopingListFuture = (item) => ({
  type: USER_ACCEPT_SHOPINGLIST_FUTURE,
  item,
});
export const userAcceptMetricsFuture = (item) => ({
  type: USER_ACCEPT_METRICS_FUTURE,
  item,
});
export const updateItemsFromLocalStorage = (item) => ({
  type: UPDATE_ITEMS_FROM_LOCALSTORAGE,
  item,
});
export const updateUserItemsFromLocalStorage = (item) => ({
  type: UPDATE_USER_ITEMS_FROM_LOCALSTORAGE,
  item,
});
// export default {
//   trigerVisibleList,
//   pushItemToList,
//   deleteItemFromList,
//   updateItemOnList,
//   pushUserItemToList,
//   updateUserItemOnList,
//   deleteUserItemFromList,
//   usserLogged,
//   usserLoggout,
//   clearAllShopingList,
//   setErr,
//   userAccept,
//   sendToken,
//   userCanCrop,
//   userCanNotCrop,
//   closeVisibleList,
//   userAcceptShopingListFuture,
//   userAcceptMetricsFuture,
// };
