import styled from "styled-components";
import { Theme } from "../../../store/theme/theme";

export const SortStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // height: 60px;
  flex-wrap: wrap;
  width: 100%;
  & > h1 {
    font-size: ${Theme.size.h1};
    font-family: "Montserrat";
    padding: 20px;
    text-transform: uppercase;
    user-select: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  & > h1 > span {
    cursor: pointer;
    transition: 0.3s;
  }
  & > h1 > span:hover {
    transform: scale(1.1);
  }
  & > label {
    padding: 15px;
    text-transform: uppercase;
  }
  & > label > select {
    margin: 10px;
    padding: 5px;
    text-transform: uppercase;
    border: 1px solid
      ${({ isDark }) =>
        isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    border-radius: 5px;
  }
`;
