export const checkOffset = (ref, scroll, option) => {
  const sectionOffsetTop = ref.current == null ? null : ref.current;

  const offSetTop = sectionOffsetTop
    ? sectionOffsetTop.offsetTop - option
    : null;
  const winHeight = window && window.innerHeight;
  const active = scroll + winHeight >= offSetTop ? true : false;
  return active;
};
