import styled from "styled-components";
export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div > div > button {
    position: absolute;
    top: 20px;
    left: 20px;
  }
  &:hover {
    cursor: pointer;
  }
`;
