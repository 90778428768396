import { Theme } from "../store/theme/theme";

export const SvgColorStyle = (theme) => {
  if (theme && typeof theme === "object" && theme.isDark) {
    return theme.isDark
      ? Theme.dark.thirdColor.white
      : Theme.dark.sixColor.dark;
  }
  if (theme && typeof theme === "boolean") {
    return theme ? Theme.dark.thirdColor.white : Theme.dark.sixColor.dark;
  }
};
