export const TRIGER_VISIBLE_LIST = "TRIGER_VISIBLE_LIST";
export const PUSH_ITEM_TO_LIST = "PUSH_ITEM_TO_LIST";
export const DELETE_ITEM_FROM_LIST = "DELETE_ITEM_FROM_LIST";
export const UPDATE_ITEM_ON_LIST = "UPDATE_ITEM_ON_LIST";
export const PUSH_USER_ITEM_TO_LIST = "PUSH_USER_ITEM_TO_LIST";
export const DELETE_USER_ITEM_TO_LIST = "DELETE_USER_ITEM_TO_LIST";
export const UPDATE_USER_ITEM_TO_LIST = "UPDATE_USER_ITEM_TO_LIST";
export const USER_LOGGED = "USER_LOGGED";
export const USER_LOGOUT = "USER_LOGOUT";
export const CLEAR_ALL_SHOPING_LIST = "CLEAR_ALL_SHOPING_LIST";
export const SET_ERR = "SET_ERR";
export const USER_RODO_ACCEPT = "USER_RODO_ACCEPT";
export const SEND_TOKEN_TO_USER = "SEND_TOKEN_TO_USER";
export const USER_CAN_CROP_FROM_NEWSPAPER = "USER_CAN_CROP_FROM_NEWSPAPER";
export const USER_CAN_NOT_CROP_FROM_NEWSPAPER =
  "USER_CAN_NOT_CROP_FROM_NEWSPAPER";
export const ClOSE_VISIBLE_LIST = "ClOSE_VISIBLE_LIST";
export const USER_ACCEPT_SHOPINGLIST_FUTURE = "USER_ACCEPT_SHOPINGLIST_FUTURE";
export const USER_ACCEPT_METRICS_FUTURE = "USER_ACCEPT_METRICS_FUTURE";
export const UPDATE_ITEMS_FROM_LOCALSTORAGE = "UPDATE_ITEMS_FROM_LOCALSTORAGE";
export const UPDATE_USER_ITEMS_FROM_LOCALSTORAGE =
  "UPDATE_USER_ITEMS_FROM_LOCALSTORAGE";
// export default {
//   TRIGER_VISIBLE_LIST,
//   PUSH_ITEM_TO_LIST,
//   PUSH_USER_ITEM_TO_LIST,
//   DELETE_ITEM_FROM_LIST,
//   DELETE_USER_ITEM_TO_LIST,
//   UPDATE_ITEM_ON_LIST,
//   UPDATE_USER_ITEM_TO_LIST,
//   USER_LOGGED,
//   USER_LOGOUT,
//   CLEAR_ALL_SHOPING_LIST,
//   SET_ERR,
//   USER_RODO_ACCEPT,
//   SEND_TOKEN_TO_USER,
//   USER_CAN_CROP_FROM_NEWSPAPER,
//   USER_CAN_NOT_CROP_FROM_NEWSPAPER,
//   ClOSE_VISIBLE_LIST,
//   USER_ACCEPT_SHOPINGLIST_FUTURE,
//   USER_ACCEPT_METRICS_FUTURE,
// };
