import { Theme } from "../../store/theme/theme";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

export const TopWrapper = styled(motion.div)`
  width: 50px;
  height: 50px;
  background: ${Theme.light.secondaryColor.green};
  border: 1px solid
    ${({ isDark }) =>
      isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  color: ${({ isDark }) =>
    isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  border-radius: 25px;
  position: fixed;
  bottom: 70px;
  display: flex;
  right: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 20;
  &:hover {
    transform: scale(1.01);
    box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
  }
`;
