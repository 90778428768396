import React from "react";
import styled from "styled-components";

import { Theme } from "../../store/theme/theme";

const plusPath =
  "M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-6 400H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h340c3.3 0 6 2.7 6 6v340c0 3.3-2.7 6-6 6z";
const minusPath =
  "M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64V160h160v256zm224 0H288V160h160v256z";
const SwitchWrapper = styled.div`
  width: 70px !important;
  height: 30px;
  border: 1px solid black;
  border-radius: 12.5px;
  position: relative;
  cursor: pointer;
  margin-right: 30px;
  transition: transform 0.3s ease-out, background 1s;
  order: unset;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.fiveColor.white : Theme.light.thirdColor.dark};
  &:hover {
    box-shadow: 2px 2px 7px black;
  }
  @media${Theme.device.tablet} {
    // order: 2;
    margin-right: 0;
    margin: 10px;
  }
  @media${Theme.device.mobileL} {
    display: none;
  }
`;
const ActiveThemePointer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transition: transform 0.3s ease-out;
  transform: ${({ isDark }) =>
    isDark
      ? "translate(0, -50%) scale(1.05)"
      : " translate(-100%, -50%) scale(1.05)"};
  width: 35px !important;
  height: 30px !important;
  background: ${({ isDark }) =>
    isDark ? Theme.light.primaryColor.white : Theme.light.primaryColor.white};
  border: 1px solid black;
  border-radius: 12.5px;
  transition: transform 0.2s ease-out;
`;

export const SinglePageSwitcher = ({ theme, toggleTheme, singlePage }) => {
  const sunSvgForLight = {
    position: "absolute",
    top: "50%",
    left: "0",
    transform: "translate(25%, -50%)",
    width: "20px",
    color: `${Theme.light.thirdColor.dark}`,
    transition: "color .2s",
  };
  const sunSvgForDark = {
    position: "absolute",
    top: "50%",
    left: "0",
    transform: " translate(25%, -50%)",
    width: "20px",
    color: `${Theme.dark.thirdColor.white}`,
    transition: "color .2s",
  };
  const moonSvgForLight = {
    position: "absolute",
    top: "50%",
    right: "0",
    transform: "translate(-25%, -50%)",
    width: "20px",
    color: `${Theme.light.primaryColor.white}`,
    transition: "color .2s",
  };
  const moonSvgForDark = {
    position: "absolute",
    top: "50%",
    right: "0",
    transform: " translate(-25%, -50%)",
    width: "20px",
    color: `${Theme.dark.primaryColor.dark}`,
    transition: "color .2s",
  };

  const moonSvgStyle = singlePage ? moonSvgForDark : moonSvgForLight;
  const sunSvgStyle = singlePage ? sunSvgForDark : sunSvgForLight;

  return (
    <>
      <SwitchWrapper
        onClick={() => toggleTheme(!singlePage)}
        isDark={theme.isDark}
      >
        <ActiveThemePointer isDark={singlePage} />
        {/* <Minus style={sunSvgStyle} /> */}
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="columns"
          className="svg-inline--fa fa-columns fa-w-16"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          style={sunSvgStyle}
          width={20}
        >
          <path fill="currentColor" d={minusPath}></path>
        </svg>
        {/* <Plus style={moonSvgStyle} /> */}
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="far"
          data-icon="square"
          className="svg-inline--fa fa-square fa-w-14"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
          style={moonSvgStyle}
          width={20}
        >
          <path fill="currentColor" d={plusPath}></path>
        </svg>
      </SwitchWrapper>
    </>
  );
};
