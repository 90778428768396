export const calculatePercentegeOfImage = (imgDimensions, targetCord) => {
  const containerSize = 20;
  const top =
    100 * ((targetCord.yStart - containerSize) / imgDimensions.height);
  const left = 100 * (targetCord.xStart / imgDimensions.width);

  return {
    top: top,
    left: left,
  };
};
