import {
  GET_USER_LOCALIZATION,
  ARROUND_OPTION,
  PARTNERS_ARROUND_USER_FOUNDED,
  SORT_SHOPS_BY_CORDS,
  CLEAR_SHOPS,
  ADD_PARTNERS_NEAR_USER,
  ERROR_FROM_PARTNERS_NEAR_USER,
  DISALOW_TO_USER_GEOLOCATION,
} from "../types/types";

export const getUserLocalization = (item) => ({
  type: GET_USER_LOCALIZATION,
  item,
});
export const setArroundOption = (item) => ({
  type: ARROUND_OPTION,
  item,
});
export const setPartnersArroundUser = (item) => ({
  type: PARTNERS_ARROUND_USER_FOUNDED,
  item,
});
export const setShopsByCoords = (item) => ({
  type: SORT_SHOPS_BY_CORDS,
  item,
});
export const deleteShopsNearUser = (item) => ({
  type: CLEAR_SHOPS,
  item,
});
export const addShopsNearUser = (item) => ({
  type: ADD_PARTNERS_NEAR_USER,
  item,
});
export const errorPartnersNearUser = (item) => ({
  type: ERROR_FROM_PARTNERS_NEAR_USER,
  item,
});
export const disalowToUserGeolocation = () => ({
  type: DISALOW_TO_USER_GEOLOCATION,
});
// export default {
//     getUserLocalization,
//     setArroundOption,
//     setPartnersArroundUser,
//     setShopsByCoords,
//     deleteShopsNearUser,
//     addShopsNearUser,
//     errorPartnersNearUser,
//     disalowToUserGeolocation
// }
