import React from "react";

export const SelectContentFromOption = ({ value, click, name }) => {
  const optionName = name;
  const optionValue = value;

  return (
    <li
      onClick={(name, value) => click({ name: optionName, value: optionValue })}
    >
      {value}
    </li>
  );
};
