export const GET_USER_LOCALIZATION = "GET_USER_LOCALIZATION";
export const ARROUND_OPTION = "ARROUND_OPTION";
export const PARTNERS_ARROUND_USER_FOUNDED = "PARTNERS_ARROUND_USER_FOUNDED";
export const SORT_SHOPS_BY_CORDS = "SORT_SHOPS_BY_CORDS";
export const CLEAR_SHOPS = "CLEAR_SHOPS";
export const ADD_PARTNERS_NEAR_USER = "ADD_PARTNERS_NEAR_USER";
export const ERROR_FROM_PARTNERS_NEAR_USER = "ERROR_FROM_PARTNERS_NEAR_USER";
export const DISALOW_TO_USER_GEOLOCATION = "DISALOW_TO_USER_GEOLOCATION";
// export default {
//     GET_USER_LOCALIZATION,
//     ARROUND_OPTION,
//     PARTNERS_ARROUND_USER_FOUNDED,
//     SORT_SHOPS_BY_CORDS,
//     CLEAR_SHOPS,
//     ADD_PARTNERS_NEAR_USER,
//     ERROR_FROM_PARTNERS_NEAR_USER,
//     DISALOW_TO_USER_GEOLOCATION
// }
